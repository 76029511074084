import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import DeviceResult from "./DeviceResult";
import SearchDisclaimer from "./SearchDisclaimer";
import ManufacturerDetailsHeaders from "./ManufacturerDetailsHeaders";
import "../styles/styles.css";
import Address from "./shared/Address";
import TextWithTooltip from "./shared/TextWithTooltip";
import { useParams } from "react-router-dom";
import scrollToTop from "./shared/ScrollToTop";
import moment from "moment";
import Back from "./Back";
import Pagination from "./shared/Paginate";
import handlePageChange from "./shared/HandlePageChange";

export default function ManufacturerDetails(props) {
  const [returnedDevicesByManufacturer, setReturnedDevicesByManufacturer] =
    useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [gotData, setGotData] = useState(false);
  const pageSize = 20;
  const [startIndex, setStartIndex] = useState(currentPage * pageSize);
  const [endIndex, setEndIndex] = useState(startIndex + pageSize);
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const pageNumbers = [];

  let createdDate = new Date(props.selectedManu.MAN_CREATED_DATE);
  let { id } = useParams();

  async function apifunc() {
    const data = {
      searchTerm: id,
    };
    try {
      const post = await axios.post("/searchDevicesByManufacturers", data);
      if (!props.selectedManu) {
        const postManu = await axios.post("/searchManufacturersById", data);

        props.setSelectedManu(postManu.data[0]);
      }
      setReturnedDevicesByManufacturer(post.data);
      setCurrentData(post.data.slice(startIndex, endIndex));
      setPageCount(Math.ceil(post.data.length / pageSize));
      setGotData(true);
    } catch (err) {
      console.log(err);
    }
  }

  function getDevicesByManufacturer() {
    scrollToTop();
    apifunc();
  }

  function handlePageClicked(newPage) {
    handlePageChange(
      newPage,
      setCurrentPage,
      currentPage,
      startIndex,
      setStartIndex,
      setEndIndex,
      setCurrentData,
      setPageCount,
      pageSize,
      returnedDevicesByManufacturer
    );
  }

  if (!gotData && !props.testing) {
    getDevicesByManufacturer();
  } else {
    return (
      <>
        <Back navigate={props.navigate} />
        <SearchDisclaimer />
        <br />
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="p" component="h3">
              Full Manufacturer Summary
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="p" component="p" className="text-align-right">
              <CSVLink
                data-testid="manufacturerCsvLink"
                data-cy="downloadCsvLink"
                data={[props.selectedManu]
                  .map((row) => ({
                    ...row,
                    MAN_CREATED_DATE: moment(row.MAN_CREATED_DATE).format(
                      "YYYY-MM-DD"
                    ),
                  }))
                  .concat(returnedDevicesByManufacturer)
                  .map(
                    ({ DEVICE_ID, DEVICE_STATUS_ID, ...keepAttrs }) => keepAttrs
                  )}
                headers={ManufacturerDetailsHeaders}
                filename={props.selectedManu.MAN_ORGANISATION_NAME + ".csv"}
              >
                Download CSV
              </CSVLink>
            </Typography>
          </Grid>
        </Grid>
        <div className="manufacturer-details-container">
          <Grid container spacing={3}>
            <Grid item xs>
              {/* The MHRA Reference Number */}
              <TextWithTooltip
                className="text-align-left-bold"
                hint="The Organisation Account Number"
                text={
                  "MHRA Reference Number: " +
                  props.selectedManu.MAN_ACCOUNT_NUMBER
                }
              />
            </Grid>
            <Grid item xs>
              {/* The date the manufacturer registered */}
              <TextWithTooltip
                className="text-align-right-bold"
                hint="The Date of Manufacturer Registration"
                text={
                  "Date Registered: " + moment(createdDate).format("DD/MM/YYYY")
                }
              />
            </Grid>
          </Grid>
          {/* The name of the manufacturer */}
          <TextWithTooltip
            hint="The Organisation Name"
            text={props.selectedManu.MAN_ORGANISATION_NAME}
            component="h3"
            className="text-with-tooltip-heading"
          />
          <div>
            <br />
            <Grid container spacing={3}>
              <Grid item xs>
                {/* Manufacturer Address */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="The Address of the Legal Manufacturer"
                  text="Manufacturer Address:"
                />
                <Address
                  addressDetails={[
                    props.selectedManu.MAN_ADDR_LINE_1,
                    props.selectedManu.MAN_ADDR_LINE_2,
                    props.selectedManu.MAN_ADDR_LINE_3,
                    props.selectedManu.MAN_ADDR_LINE_4,
                    props.selectedManu.MAN_CITY,
                    props.selectedManu.MAN_COUNTYSTATEPROVINCE,
                    props.selectedManu.MAN_COUNTRY,
                    props.selectedManu.MAN_POSTCODE,
                  ]}
                />
                <br></br>
                {/* Manufacturer Customer Service Email */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="Manufacturer Customer Service Email Address"
                  text="Customer Service Email:"
                />
                <Typography variant="p">
                  {props.selectedManu.CUSTOMER_SERVICE_EMAIL_ADDRESS ||
                    "Not Available"}
                </Typography>

                <br></br>
                <br></br>
                {/* Manufacturer Customer Service Telephone Number */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="Manufacturer Customer Service Telephone Number"
                  text="Customer Service Telephone Number:"
                />
                <Typography variant="p">
                  {props.selectedManu.CUSTOMER_SERVICE_TELEPHONE_NUMBER ||
                    "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs>
                {/* UK Responsible Person */}
                <div>
                  <TextWithTooltip
                    className="text-align-left-bold"
                    text="UK Responsible Person/Northern Ireland Authorised Representative:"
                    hint="The UK Representative Organisation Name"
                  />
                  <Typography variant="p">
                    {props.selectedManu.REP_NAME || "Not Applicable"}
                  </Typography>
                </div>
                <br />
                {/* Relationship of responsible person */}
                <div>
                  <TextWithTooltip
                    className="text-align-left-bold"
                    text="Relationship:"
                    hint="The Relationship between UK Representative and the Legal Manufacturer"
                  />
                  <Typography variant="p">
                    {props.selectedManu.RELATIONSHIP || "Not Applicable"}
                  </Typography>
                </div>
                <br />
                {/* Representative address */}
                <div>
                  <TextWithTooltip
                    className="text-align-left-bold"
                    text="Representative Address:"
                    hint="The Address of the UK Representative"
                  />
                  {props.selectedManu.REP_ADDRESS_LINE_1 ||
                  props.selectedManu.REP_ADDRESS_LINE_2 ||
                  props.selectedManu.REP_ADDRESS_LINE_3 ||
                  props.selectedManu.REP_ADDRESS_LINE_4 ||
                  props.selectedManu.REP_CITY ||
                  props.selectedManu.REP_COUNTY_STATE_PROVINCE ||
                  props.selectedManu.REP_COUNTRY ||
                  props.selectedManu.REP_POSTCODE ? (
                    <Address
                      addressDetails={[
                        props.selectedManu.REP_ADDRESS_LINE_1,
                        props.selectedManu.REP_ADDRESS_LINE_2,
                        props.selectedManu.REP_ADDRESS_LINE_3,
                        props.selectedManu.REP_ADDRESS_LINE_4,
                        props.selectedManu.REP_CITY,
                        props.selectedManu.REP_COUNTY_STATE_PROVINCE,
                        props.selectedManu.REP_COUNTRY,
                        props.selectedManu.REP_POSTCODE,
                      ]}
                    />
                  ) : (
                    <p data-testid="nullRepAddress">Not Applicable</p>
                  )}
                </div>
                <br></br>
                {/* Representitive Customer Service Email */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="Representative Customer Service Email Address"
                  text="Customer Service Email:"
                />
                <Typography variant="p">
                  {props.selectedManu.REP_CUSTOMER_SERVICE_EMAIL_ADDRESS ||
                    "Not Available"}
                </Typography>
                <br></br>
                <br></br>
                {/* Representative Customer Service Telephone Number */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="Representative Customer Service Telephone Number"
                  text="Customer Service Telephone Number:"
                />
                <Typography variant="p">
                  {props.selectedManu.REP_CUSTOMER_SERVICE_TELEPHONE_NUMBER ||
                    "Not Available"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <br />
        {returnedDevicesByManufacturer[0] ? (
          <>
            {" "}
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography variant="p" component="h3">
                  Devices ({returnedDevicesByManufacturer.length})
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="p"
                  component="p"
                  className={"text-align-right"}
                >
                  Page {currentPage + 1} of {pageCount}
                </Typography>
              </Grid>
            </Grid>
            <div>
              {currentData.map((device) => {
                return (
                  <DeviceResult
                    key={device.DEVICE_ID}
                    device={device}
                    setSelectedDev={props.setSelectedDev}
                    accessedFromManufacturerDetails={true}
                    setSelectedType={props.setSelectedType}
                    navigate={props.navigate}
                    showStatus={true}
                    pageNumbers={pageNumbers}
                  />
                );
              })}
              <Pagination
                currentPage={currentPage}
                onPageChange={handlePageClicked}
                pageSize={pageSize}
                totalItems={returnedDevicesByManufacturer.length}
                showStatus={true}
              />
            </div>
          </>
        ) : (
          <>
            <Typography variant="p" component="h3">
              Devices
            </Typography>
            <br />
            <Typography variant="p" component="h3" className="result-container">
              Devices pending update by manufacturer
            </Typography>
            <br />
          </>
        )}
      </>
    );
  }
}
